import { useState, useRef, useEffect } from "react";
import { FaStar } from "react-icons/fa";
const CustomTooltip = ({ chart, valueFormatter, chart_type }) => {
  const [tooltipData, setTooltipData] = useState(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (!chart.current) return;

    // Get the actual Chart.js instance
    const chartInstance = chart.current?.chartInstance || chart.current;

    // Direct event handler for tooltip tracking
    const handleMouseMove = (event) => {
      if (!chartInstance) return;

      // Use Chart.js built-in tooltip methods
      const activePoints = chartInstance.getElementsAtEventForMode(
        event,
        "nearest",
        { intersect: false },
        false
      );

      if (activePoints.length === 0) {
        setTooltipData(null);
        return;
      }

      const firstPoint = activePoints[0];
      const dataset = chartInstance.data.datasets[firstPoint.datasetIndex];
      const dataIndex = firstPoint.index;
      const label = chartInstance.data.labels[dataIndex];

      const tooltipPosition = chartInstance.canvas.getBoundingClientRect();
      const canvasX = event.clientX - tooltipPosition.left;
      const canvasY = event.clientY - tooltipPosition.top;

      const customTooltipData = {
        dataPoints: chartInstance.data.datasets.map((dataset, index) => ({
          label: dataset.label,
          value: dataset.data[dataIndex],
          color: dataset.borderColor,
          index: dataIndex,
        })),
        position: {
          x: canvasX,
          y: canvasY,
        },
        labelIndex: label,
      };

      setTooltipData(customTooltipData);
    };

    const canvas = chartInstance.canvas;

    // Add event listeners
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseout", () => setTooltipData(null));

    // Cleanup
    return () => {
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.removeEventListener("mouseout", () => setTooltipData(null));
    };
  }, [chart, valueFormatter]);

  if (!tooltipData) return null;

  return (
    <div
      ref={tooltipRef}
      className=" bg-[#0C111D] text-white rounded-lg shadow-lg p-3 border border-gray-700 z-50 pointer-events-none absolute"
      style={{
        left: `${tooltipData.position.x}px`,
        top: `${tooltipData.position.y}px`,
        transform: "translate(-50%, -110%)",
      }}
    >
      {/* Tooltip Header */}
      <div className="mb-2 border-b border-gray-700 pb-2">
        <p className="text-xs text-gray-200 text-left">
          {tooltipData.labelIndex}
        </p>
      </div>

      {/* Tooltip Content */}
      {tooltipData.dataPoints.map((point, index) => (
        <div key={index} className="flex items-center mb-1 last:mb-0 w-full">
          {/* Label and Value */}
          <div className="flex justify-between w-full">
            <span
              className={`text-xs font-medium`}
              style={{
                color: point.color,
              }}
            >
              {point.label}
            </span>
            <span
              className={"text-xs font-bold ml-6 flex items-center"}
              style={{
                color: point.color,
              }}
            >
              {chart_type === "prices" &&
              valueFormatter(point.value) !== "NaN" ? (
                "$"
              ) : chart_type === "offers" && point.label === "Ratings" ? (
                <FaStar />
              ) : null}
              {valueFormatter(point.value) === "NaN"
                ? "-"
                : valueFormatter(point.value)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomTooltip;

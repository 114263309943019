import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const ToggleWithText = ({
  defaultChecked,
  onLabel = "ON",
  offLabel = "OFF",
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const selectedFulfillment = useSelector(
    (state) => state.globalStates.selectedFulfillment
  );

  const handleToggle = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    if (onChange) {
      onChange(newValue ? "FBA" : "FBM");
    }
  };

  useEffect(() => {
    setIsChecked(selectedFulfillment === "FBA");
  }, [selectedFulfillment]);

  return (
    <button
      onClick={handleToggle}
      className={`relative inline-flex items-center justify-center min-w-[90px] h-8 rounded-full px-4 ml-auto
        transition-colors duration-200 ease-in-out outline-none
        bg-primary `}
    >
      <span
        className={`absolute  text-sm font-medium transition-opacity duration-200 text-primary-light
          ${isChecked ? " left-2 right-8" : "left-6 right-2"}`}
      >
        {isChecked ? onLabel : offLabel}
      </span>
      <div
        className={`absolute left-2 h-5 w-5 rounded-full bg-secondary shadow-md transform transition-transform 
          duration-200 ease-in-out ${
            isChecked ? "translate-x-[50px]" : "translate-x-0"
          }`}
      />
    </button>
  );
};

export default ToggleWithText;

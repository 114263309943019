import { combineReducers } from "redux";

import auth from "./auth";
import userData from "./userReducers";
import GlobalStates from "./GlobalStates";
import settings from "./settings";
import score from "./score";

export default combineReducers({
  auth: auth,
  userData: userData,
  globalStates: GlobalStates,
  settings: settings,
  score: score,
});

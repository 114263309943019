import React, { useState, useEffect } from "react";
import SkeletonLine from "./SkeletonLine";
import {
  calculateProfitMargin,
  calculateROI,
  calculateMaximumCost,
  calculateBreakevenSalePrice,
} from "../utils/ProfitCalculator";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import TooltipSimple from "./SettingComponents/TooltipSimple";
import { formatNumber } from "../utils/formatData";

const ProductDetails = ({
  data,
  loading,
  amazonFees,
  salesData,
  settingsData,
  referralFeePercent,
  alerts,
  asin,
  basicInfo,
}) => {
  const dispatch = useDispatch();
  const totalFees = useSelector((state) => state.globalStates.total_fees);
  const prices = useSelector((state) => state.globalStates.prices);
  const settings = useSelector((state) => state.globalStates.settings);
  const selectedFulfillment = useSelector(
    (state) => state.globalStates.selectedFulfillment
  );

  const [profitDollars, setProfitDollars] = useState("");
  const [profitPercent, setProfitPercent] = useState("");
  const [profit, setProfit] = useState(data?.profit || "");
  const [roi, setROI] = useState(data?.roi || "");
  const [maxCost, setMaxCost] = useState(data?.max_cost || "");
  const [breakeven, setBreakeven] = useState(data?.brekeven || "");
  const [alertData, setAlertData] = useState([]);
  const [showAlerts, setShowAlerts] = useState(true);
  const [lpAlert, setLPAlert] = useState(false);

  useEffect(() => {
    let isInitialLoad = true;
    const handleBeforeUnload = () => {
      if (isInitialLoad) {
        // Skip execution on the first page load
        return;
      }
      console.log("Component unmounted");
      if (asin !== "-") {
        let cost_prices_object = JSON.parse(
          localStorage.getItem("cost_prices")
        );
        if (cost_prices_object) {
          cost_prices_object[asin] = prices.cost_price;
        } else {
          cost_prices_object = {
            [asin]: prices.cost_price,
          };
        }
        localStorage.setItem("cost_prices", JSON.stringify(cost_prices_object));
      }
    };
    // Attach the `beforeunload` event listener
    window.addEventListener("beforeunload", handleBeforeUnload);
    // Disable the flag after the initial load
    setTimeout(() => {
      isInitialLoad = false;
    }, 0);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [asin, prices.cost_price]);
  useEffect(() => {
    if (alerts) {
      let alertdata = [
        {
          title: "M",
          color: alerts?.meltable ? "bg-yellow-600" : "bg-green-600",
          tooltipText: alerts?.meltable
            ? "The product is Meltable"
            : "The product is not Meltable",
          hidden: alerts?.meltable ? false : true,
        },
        {
          title: "AMZ",
          color: alerts?.amazonShareBuyBox ? "bg-yellow-600" : "bg-green-600",
          tooltipText: alerts?.amazonShareBuyBox
            ? "Amazon holds the buy box"
            : "Amazon does not hold the buy box",
          hidden: alerts?.amazonShareBuyBox ? false : true,
        },
        {
          title: "LP",
          color: alerts?.low_price_FBA ? "bg-yellow-600" : "bg-green-600",
          tooltipText: alerts?.low_price_FBA
            ? "This product has low price FBA fee"
            : "This product does not meet the requirements for low price FBA fee",
          hidden: alerts?.low_price_FBA ? false : true,
        },
        {
          title: "V",
          color: alerts?.variations ? "bg-yellow-600" : "bg-green-600",
          tooltipText: alerts?.variations
            ? "The product has some variations"
            : "The product do not variations",
          hidden: alerts?.variations ? false : true,
        },
        {
          title: "PL",
          color: alerts?.privateLabel ? "bg-yellow-600" : "bg-green-600",
          tooltipText: alerts?.privateLabel
            ? "This product is likely to be a private label"
            : "This product is unlikely to be a private label",
          hidden: alerts?.privateLabel ? false : true,
        },
        {
          title: "S",
          color: alerts?.size?.includes("oversize")
            ? "bg-yellow-600"
            : "bg-green-600",
          tooltipText: `size: ${alerts?.size}`,
          hidden: alerts?.size?.includes("oversize") ? false : true,
        },
        {
          title: "G",
          color:
            basicInfo?.brand === "Generic" ? "bg-[#E74B4B]" : "bg-green-600",
          tooltipText: `This product have Generic brand`,
          hidden: basicInfo?.brand === "Generic" ? false : true,
        },
        {
          title: "HZ",
          color: alerts?.hazmat ? "bg-yellow-600" : "bg-green-600",
          tooltipText: `This product is hazmat`,
          hidden: alerts?.hazmat ? false : true,
        },
      ];
      const hasVisibleAlerts = alertdata.some((alert) => !alert.hidden);
      setAlertData(alertdata);
      setShowAlerts(hasVisibleAlerts);
    }
  }, [alerts]);

  useEffect(() => {
    if (data) {
      const salePrice = parseFloat(prices.sale_price) || 0;
      const costPrice = parseFloat(prices.cost_price) || 0;
      // get the cost price from localstorage
      const cost_prices_object = JSON.parse(
        localStorage.getItem("cost_prices")
      );

      if (cost_prices_object && asin !== "-") {
        dispatch({
          type: "SET_PRICES",
          payload: {
            sale_price: data.prices?.sale_price || "",
            cost_price:
              cost_prices_object[asin] || data.prices?.cost_price || "",
          },
        });
      } else {
        dispatch({
          type: "SET_PRICES",
          payload: {
            sale_price: data.prices?.sale_price || "",
            cost_price: data.prices?.cost_price || "",
          },
        });
      }
      const [profitDollars, profitPercent] = calculateProfitMargin(
        salePrice,
        cost_prices_object && asin !== "-" && cost_prices_object[asin]
          ? cost_prices_object[asin]
          : costPrice,
        totalFees
      );
      setProfitDollars(profitDollars || "");
      setProfitPercent(data.profit_percent || "");
      setProfit(data.profit || "");
      setROI(data.roi || "");
      setMaxCost(data.max_cost || "");
    }
  }, [data]);

  const handlePriceChange = (e, type) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");

    if (type === "cost_price" || type === "sale_price") {
      if (value.length > 2) {
        const dollars = value.slice(0, -2);
        const cents = value.slice(-2);
        value = `${dollars}.${cents}`;
      }
    }

    dispatch({
      type: "SET_PRICES",
      payload: {
        ...prices,
        [type]: value,
      },
    });
  };

  useEffect(() => {
    const salePrice = parseFloat(prices.sale_price) || 0;
    const costPrice = parseFloat(prices.cost_price) || 0;

    const [profitDollarsTemp, profitPercent] = calculateProfitMargin(
      salePrice,
      costPrice,
      totalFees
    );

    const roiValue = calculateROI(salePrice, costPrice, totalFees);

    // setmaxcost only ifsale price changes
    if (
      prices.sale_price !== data?.prices?.sale_price ||
      (settings?.defaultValues?.local_fullfillment &&
        settings?.defaultValues?.local_fullfillment !== selectedFulfillment)
    ) {
      const max_cost = calculateMaximumCost(
        salePrice,
        costPrice,
        totalFees,
        Number(settings?.buyingCriteria?.minProfit) || 0,
        profitDollarsTemp,
        settings
      );
      setMaxCost(max_cost);
    } else {
      setMaxCost(data?.max_cost);
    }
    // set breakeven only if cost price changes
    if (prices.cost_price !== data?.prices?.cost_price) {
      const breakevenSalePrice = calculateBreakevenSalePrice(
        costPrice,
        salePrice,
        totalFees,
        referralFeePercent
      );
      setBreakeven(breakevenSalePrice);
    } else {
      setBreakeven(data?.brekeven);
    }
    // if product is low price and if new profit with greater sale price is lower then show alert
    if (alerts?.low_price_FBA && salePrice >= 10) {
      if (
        profitDollarsTemp !== "--" &&
        Number(profitDollarsTemp) < Number(data.profit)
      ) {
        setLPAlert(true);
      }
    }
    // setMaxCost(`${max_cost}`);
    setProfitDollars(
      profitDollarsTemp && profitDollarsTemp !== "NaN"
        ? profitDollarsTemp
        : "--"
    );
    setProfitPercent(
      profitPercent && profitPercent !== "NaN" ? profitPercent : "--"
    );
    setProfit(`$${profitDollarsTemp}`);
    setROI(`${roiValue.toFixed(2)}`);
  }, [prices, totalFees]);

  const bsr = data?.BSR;
  const estimatedPrice = salesData?.sales_estimation?.monthly_sales;

  return (
    <div className="product-details-container rounded-lg text-white">
      {showAlerts ? (
        <div className="w-full bg-secondary mt-2 rounded-lg flex flex-col gap-y-1.5 px-4 py-1.5 items-center justify-center">
          <div className="text-left text-sm">Alerts</div>
          <div className="flex items-center gap-x-3">
            {loading
              ? [1, 2, 3, 4, 5, 6].map((e) => {
                  return (
                    <div className="animate-pulse" key={e} role="status">
                      <div className="rounded-md bg-primary px-3 py-2"></div>
                    </div>
                  );
                })
              : alertData.length > 0 &&
                alertData.map(({ title, color, tooltipText, hidden }, i) => {
                  return (
                    <div
                      className={`${
                        title === "LP" && lpAlert ? "bg-[#E74B4B]" : color
                      } px-2 py-1 rounded-md group relative ${
                        hidden ? "hidden" : ""
                      }`}
                      key={i}
                    >
                      <p className="font-semibold text-white text-[10px]">
                        {title}
                      </p>
                      <TooltipSimple
                        text={
                          title === "LP" && lpAlert
                            ? "This product is more profitable at low price fee"
                            : tooltipText
                        }
                      />
                    </div>
                  );
                })}
          </div>
        </div>
      ) : null}
      <div className="flex justify-between mt-3" style={{ fontSize: "14px" }}>
        {/* <div className="flex mt-2"> */}
        <div
          className={`rounded-lg text-center relative mr-2 min-w-[31%] 
                ${
                  Number(settingsData?.buyingCriteria?.minbsr) === 0
                    ? "text-primary-light bg-secondary"
                    : Number(settingsData?.buyingCriteria?.minbsr) >
                        Number(
                          data?.BSR?.percentage?.substring(
                            0,
                            data?.BSR?.percentage.length - 1
                          )
                        ) || bsr?.BSR === -1
                    ? "text-white bg-[#E74B4B]"
                    : "text-primary bg-primary-light"
                }`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-3 pt-1">BSR</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              {bsr?.BSR !== -1 ? (
                <>
                  <span className="mr-1 overflow-hidden text-ellipsis whitespace-nowrap">
                    {bsr?.BSR ? formatNumber(Number(bsr?.BSR)) : "--"}
                  </span>
                  <span>
                    {data?.BSR?.percentage
                      ? "(" + data?.BSR?.percentage + ")"
                      : "(--)"}
                  </span>
                </>
              ) : (
                "--"
              )}
              <TooltipSimple
                text={
                  Number(settingsData?.buyingCriteria?.minbsr) >
                  Number(data?.BSR?.BSR)
                    ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})`
                    : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                }
              />
            </div>
          )}
        </div>

        <div
          className={`rounded-lg text-center relative mr-2 min-w-[31%] text-primary-light bg-secondary`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-3 pt-1">Est. Sales</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                {estimatedPrice !== -1 && estimatedPrice
                  ? formatNumber(estimatedPrice)
                  : "--"}
              </span>
              <TooltipSimple
                text={
                  salesData?.sales_estimation?.message
                    ? salesData?.sales_estimation?.message
                    : estimatedPrice !== -1 && estimatedPrice
                    ? `We estimate this product sells ${formatNumber(
                        estimatedPrice
                      )} units per month`
                    : "Est. Sales: --"
                }
                width={"extra"}
              />
            </div>
          )}
        </div>

        <div
          className={`rounded-lg text-center relative min-w-[31%] text-primary-light bg-secondary`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-3 pt-1">Max. Cost</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                {/* show -- if NaN as well */}
                {maxCost && maxCost !== "NaN" && maxCost > 0
                  ? "$" + maxCost
                  : maxCost < 0
                  ? "NOT PROFITABLE"
                  : "--"}
              </span>
              <TooltipSimple
                text={`Maximum Cost: ${
                  maxCost && maxCost !== "NaN" ? "$" + maxCost : "--"
                }`}
              />
            </div>
          )}
        </div>
        {/* </div> */}
      </div>

      <div className="flex justify-between mt-3" style={{ fontSize: "14px" }}>
        <div className="box flex-1 rounded-lg mr-4 bg-secondary">
          <div className="flex items-center">
            <div className="w-[40%] text-center px-1">Cost Price</div>
            <div className="text-primary-light flex-1">
              {loading ? (
                <SkeletonLine />
              ) : (
                <input
                  type="text"
                  value={`$${prices.cost_price}`}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[^0-9]/g, "");
                    e.target.value = `$${rawValue}`;
                    handlePriceChange(e, "cost_price");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  className={`text-center text-white border border-primary-light rounded-xl bg-primary-dark`}
                  style={{ borderRadius: "12px" }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="box flex-1 rounded-lg mr-4 bg-secondary">
          <div className="flex items-center">
            <div className="w-[42%] text-center px-1">Sales Price</div>
            <div className="text-primary-light flex-1">
              {loading ? (
                <SkeletonLine />
              ) : (
                <input
                  type="text"
                  value={`$${prices.sale_price}`}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[^0-9]/g, "");
                    e.target.value = `$${rawValue}`;
                    handlePriceChange(e, "sale_price");
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  className={`text-center text-white border border-primary-light rounded-xl bg-primary-dark`}
                  style={{ borderRadius: "12px" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex justify-between mt-3 gap-x-2"
        style={{ fontSize: "14px" }}
      >
        <div
          className={`rounded-lg text-center relative mr-2 ${
            settingsData?.quickInfo?.show_profit === true ? "block" : "hidden"
          } flex-1 ${
            profitDollars < Number(settingsData?.buyingCriteria?.minProfit)
              ? "text-white bg-[#E74B4B]"
              : "text-primary bg-primary-light"
          }`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-3 pt-1">Profit</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="mr-1 text-ellipsis whitespace">
                {profit ? profit : "--"}
              </span>
              {/* <TooltipSimple
                                    text={
                                        (Number(settingsData?.buyingCriteria?.minbsr) > Number(data?.BSR?.BSR)) ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})` : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                                    }
                                /> */}
            </div>
          )}
        </div>
        <div
          className={`rounded-lg text-center relative mr-2 ${
            settingsData?.quickInfo?.show_profit_margin === true
              ? "block"
              : "hidden"
          } flex-1 ${
            profitDollars < Number(settingsData?.buyingCriteria?.minProfit)
              ? "text-white bg-[#E74B4B]"
              : "text-primary bg-primary-light"
          }`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-1 pt-1 text-sm">
              Profit Margin
            </div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="mr-1 text-ellipsis whitespace">
                {profitPercent ? profitPercent : "--"}
              </span>
              {/* <TooltipSimple
                                    text={
                                        (Number(settingsData?.buyingCriteria?.minbsr) > Number(data?.BSR?.BSR)) ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})` : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                                    }
                                /> */}
            </div>
          )}
        </div>

        <div
          className={`rounded-lg text-center relative mr-2 ${
            settingsData?.quickInfo?.show_roi === true ? "block" : "hidden"
          } flex-1 ${
            roi < Number(settingsData?.buyingCriteria?.minroi)
              ? "text-white bg-[#E74B4B]"
              : "text-primary bg-primary-light"
          }`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-3 pt-1">ROI</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="mr-1 overflow text-ellipsis whitespace">
                {roi && roi !== "NaN" ? roi + "%" : "--"}
              </span>
              {/* <TooltipSimple
                                    text={
                                        (Number(settingsData?.buyingCriteria?.minbsr) > Number(data?.BSR?.BSR)) ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})` : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                                    }
                                /> */}
            </div>
          )}
        </div>
        <div
          className={`rounded-lg text-center relative ${
            Number(prices?.sale_price) < Number(data?.brekeven)
              ? "text-white bg-[#E74B4B]"
              : "text-primary bg-primary-light"
          } flex-1 ${
            settingsData?.quickInfo?.show_breakeven === true
              ? "block"
              : "hidden"
          }`}
        >
          <div
            className="p-1 bg-secondary"
            style={{
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            <div className="text-white bg-secondary px-2 pt-1">Break Even</div>
          </div>
          {loading ? (
            <div className="mb-2 px-2">
              <SkeletonLine />{" "}
            </div>
          ) : (
            <div className="relative group text-xs w-full px-3 py-2 flex items-center justify-center font-semibold">
              <span className="mr-1 overflow-hidden text-ellipsis whitespace-nowrap">
                {breakeven ? "$" + breakeven : "--"}
              </span>
              {/* <TooltipSimple
                                    text={
                                        (Number(settingsData?.buyingCriteria?.minbsr) > Number(data?.BSR?.BSR)) ? `BSR(${data?.BSR?.BSR}) is less than desired BSR(${settingsData?.buyingCriteria?.minbsr})` : `BSR(${data?.BSR?.BSR}) is above desired BSR level (${settingsData?.buyingCriteria?.minbsr})`
                                    }
                                /> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;

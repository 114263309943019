import React, { useState, useEffect, useRef } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { convertEpochTimeToLocalTime } from "../utils/formatData";
import "chartjs-adapter-moment";
import CustomTooltip from "./CustomTooltip";

ChartJS.register(...registerables);

const BsrChart = ({
  data: chartDataProps,
  valueProperty = "value",
  labels,
  hoveredIndex,
  graph2Ref,
}) => {
  const [
    dataMain,
    dataFBM,
    dataAmazon,
    dataBsr,
    dataBb,
    dataOffers,
    dataRatings,
    dataReviews,
  ] = chartDataProps || [];
  // const bsrChartRef = useRef(null);
  const mapDataToLabels = (data, labels) => {
    const dataMap = new Map();
    data?.forEach((d) => {
      const localDate = convertEpochTimeToLocalTime(d.time);
      dataMap.set(localDate, parseInt(d[valueProperty], 10));
    });

    return labels.map((label) => dataMap.get(label) || null);
  };

  const dataBsr_L = mapDataToLabels(dataBsr, labels);

  const fillMissingValues = (data) => {
    const filledData = [];
    let lastValue = null;

    data.forEach((value) => {
      if (value !== null) {
        lastValue = value;
      }
      filledData.push(lastValue);
    });

    return filledData;
  };

  const filledDataBsr = fillMissingValues(dataBsr_L);

  const min = Math.min(...filledDataBsr);
  const max = Math.max(...filledDataBsr);

  const valueFormatter = (num) => {
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(1) + "B";
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(1) + "M";
    if (num >= 1_000) return (num / 1_000).toFixed(1) + "k";
    return num.toString();
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Sales Rank",
        data: filledDataBsr,
        borderColor: "#4ade80",
        borderWidth: 1,
        stepped: true,
        pointRadius: 0,
      },
    ],
  };

  const chartOptions = {
    isBarChart: true,
    plugins: {
      tooltip: {
        enabled: false,
        mode: "index",
        intersect: false,
        // callbacks: {
        //   label: (context) => {
        //     const label = context.dataset.label || "";
        //     const value = context.raw || 0;
        //     return `${label}: ${valueFormatter(value)}`;
        //   },
        // },
      },
      legend: {
        display: false,
      },
      // corsair: {
      //   horizontal: false,
      //   vertical: false,
      //   color: 'red',
      //   dash: [],
      //   width: 2
      // }
    },
    scales: {
      x: {
        ticks: {
          color: "#E5E7EB",
          font: {
            size: 10,
          },
        },
        grid: {
          color: "rgba(229, 229, 229, 0.3)",
        },
        border: {
          color: "#E5E7EB",
          width: 1,
        },
      },
      y: {
        suggestedMin: min - 5,
        suggestedMax: max + 5,
        ticks: {
          callback: valueFormatter,
          color: "#E5E7EB",
        },
        grid: {
          color: "rgba(229, 229, 229, 0.3)",
        },
        border: {
          color: "#E5E7EB",
          width: 1,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    // onHover: (event, chartElement) => {
    //   if (chartElement.length) {
    //     const index = chartElement[0].index;
    //     // atHover(index);
    //     hoveredIndex.current = index;
    //   } else {
    //     // onLeave();
    //   }
    // },
  };

  return (
    <React.Fragment>
      <div
        className="bg-secondary flex flex-col border rounded-lg shadow-sm w-full p-1 relative"
        style={{
          minWidth: "100%",
          minHeight: "150px",
        }}
      >
        <Line
          id="bsrchart"
          data={chartData}
          options={chartOptions}
          ref={graph2Ref}
        />
        <CustomTooltip
          chart={graph2Ref}
          valueFormatter={valueFormatter}
          chart_type={"bsr"}
        />
      </div>

      {/* <button onClick={() => {
        // bsrChartRef.current.setActiveElements([
        //   {
        //     datasetIndex: 0,
        //     index: 5,
        //   }
        // ])
        
        graph2Ref.current.corsair = { x: 200, y: 0, draw: true };
        graph2Ref.current.update();
      }}>button</button> */}
    </React.Fragment>
  );
};

export default BsrChart;

import React, { useEffect, useRef, useState, useCallback } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import { convertEpochTimeToLocalTime } from "../utils/formatData";
import CustomTooltip from "./CustomTooltip";

ChartJS.register(...registerables);

const AreaChartWithCustomTooltip = (props) => {
  const {
    data: chartDataProps,
    valueProperty = "value",
    labels,
    hoveredIndex,
    graph1Ref,
  } = props;
  // Extract data
  const [
    dataMain,
    dataFBM,
    dataAmazon,
    dataBsr,
    dataBb,
    dataOffers,
    dataRatings,
    dataReviews,
  ] = chartDataProps || [];
  // Function to map data to labels
  const mapDataToLabels = (data, labels) => {
    const dataMap = new Map();
    data?.forEach((d) => {
      const localDate = convertEpochTimeToLocalTime(d.time);
      dataMap.set(localDate, d[valueProperty]);
    });

    return labels.map((label) => dataMap.get(label) || null);
  };

  const data_L = mapDataToLabels(dataMain, labels);
  const dataFBM_L = mapDataToLabels(dataFBM, labels);
  const dataAmazon_L = mapDataToLabels(dataAmazon, labels);
  const dataBsr_L = mapDataToLabels(dataBsr, labels);
  const dataBb_L = mapDataToLabels(dataBb, labels);

  // Fill in missing values by carrying forward the last known value
  const fillMissingValues = (data) => {
    const filledData = [];
    let lastValue = null;

    data.forEach((value) => {
      if (value !== null) {
        lastValue = value;
      }
      filledData.push(lastValue);
    });

    return filledData;
  };

  const filledData = fillMissingValues(data_L);
  const filledDataFBM = fillMissingValues(dataFBM_L);
  const filledDataAmazon = fillMissingValues(dataAmazon_L);
  // const filledDataBsr = fillMissingValues(dataBsr_L);
  const filledDataBb = fillMissingValues(dataBb_L);

  const min = Math.min(
    ...filledData,
    ...filledDataFBM,
    ...filledDataAmazon,
    // ...filledDataBsr,
    ...filledDataBb
  );
  const max = Math.max(
    ...filledData,
    ...filledDataFBM,
    ...filledDataAmazon,
    // ...filledDataBsr,
    ...filledDataBb
  );

  const valueFormatter = (num) => {
    // num = num / 100;
    num = parseFloat(num).toFixed(2);
    return num.toString();
  };

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "FBA",
        data: filledData,
        borderColor: "#f97316",
        borderWidth: 1,
        stepped: true,
        pointRadius: 0,
      },
      {
        label: "FBM",
        data: filledDataFBM,
        borderColor: "#0ea5e9",
        borderWidth: 1,
        stepped: true,
        pointRadius: 0,
      },
      {
        label: "Amazon",
        data: filledDataAmazon,
        borderColor: "#ba900b",
        backgroundColor: "rgba(166, 128,10, 0.4)",
        borderWidth: 2,
        stepped: true,
        pointRadius: 0,
        fill: true,
      },
      {
        label: "Buy Box",
        data: filledDataBb,
        borderColor: "#ec4899",
        borderWidth: 1,
        stepped: true,
        pointRadius: 0,
      },
    ],
  };

  const chartOptions = {
    isAreaChart: true,
    plugins: {
      tooltip: {
        enabled: false, // Disable default tooltip
        // callbacks: {
        //   label: (context) => {
        //     const label = context.dataset.label || "";
        //     const value = context.raw || 0;
        //     return `${label}: ${valueFormatter(value)}`;
        //   },
        // },
      },
      legend: {
        display: false, // Disable legend to remove labels at the top
      },
      // corsair: {
      //     horizontal: false,
      //     vertical: true,
      //     color: 'red',
      //     dash: [],
      //     width: 2
      // }
    },
    scales: {
      x: {
        ticks: {
          color: "#E5E7EB", // gray-200
          font: {
            size: 10,
          },
        },
        grid: {
          color: "rgba(229, 229, 229, 0.3)",
        },
        border: {
          color: "#E5E7EB", // gray-200
          width: 1,
        },
      },
      y: {
        suggestedMin: min < 50 ? 0 : min - 50,
        suggestedMax: max + 50,
        ticks: {
          // callback: valueFormatter,
          color: "#E5E7EB", // gray-200
        },
        grid: {
          color: "rgba(229, 229, 229, 0.3)",
        },
        border: {
          color: "#E5E7EB", // gray-200
          width: 1,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div
      className="bg-secondary flex flex-col border rounded-lg shadow-sm w-full p-1 relative"
      style={{
        minWidth: "100%",
        minHeight: "150px",
        // minHeight: '200px'
      }}
    >
      <Line
        id="areachart"
        data={chartData}
        options={chartOptions}
        style={
          {
            // minWidth: '100%'
          }
        }
        ref={graph1Ref}
      />
      <CustomTooltip
        chart={graph1Ref}
        valueFormatter={valueFormatter}
        chart_type={"prices"}
      />
    </div>
  );
};

export default AreaChartWithCustomTooltip;

const initState = {
  scores: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case "SET_SCORES":
      return {
        ...state,
        scores: action.payload,
      };

    default:
      return state;
  }
};
